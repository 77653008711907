import "./App.css";
import { server } from "./config";
import axios from "axios";
const style = {
  backgroundColor: "#4CAF50" /* Green */,
  border: "none",
  color: "white",
  padding: "15px 32px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "16px",
  margin: "4px 2px",
  cursor: "pointer",
  borderRadius: "12px",
  boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
};
const execute = async () => {
  try {
    const response = await axios.get(`${server}api/execute`);
    alert("success");
  } catch (error) {
    console.log(error);
    alert("failed");
  }
};
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <button style={style} onClick={execute}>
          Execute
        </button>
      </header>
    </div>
  );
}

export default App;
